/*
 * Seeds
 *
 * Use this file to populate an empty Firestore or reset the content
 * of the Firestore.
 */


const seeds = {
    en: {
        'banner': 'Share Your Ideas. Click here to take the survey and tell us your experience!',

        'engagement.title': 'Engagement Survey',
        'engagement.button': 'Share Your Ideas',

        'Welcome.title': "Let's build the future of Cleveland State together.",

        'Vision.title': "The future of Cleveland State",
        'Vision.subtitle': "Vision & About",
        'Vision.body': "The campus master plan for Cleveland State College provides an extraordinary opportunity to create a compelling and comprehensive vision for the future, rooted in specific investments over the next 10 to 15 years. Cleveland State selected the Boston-based architecture and campus planning firm Sasaki to create an updated campus master plan for the College rooted in our recently completed Strategic Plan. Our hope is that the new campus master plan will provide us with a clear road map for where Cleveland State is going from now until 2035, the 150th anniversary of the College.",
        'Vision.historical.title': 'Historical',
        'Vision.historical.image': '',
        'Vision.present.title': 'Present',
        'Vision.present.image': '',

        'Process.title': "The campus plan process",
        'Process.subtitle': "Process & Timeline",
        'Process.body': 'This is a new paragraph section.',

        'Process.phase1.name': 'Phase 1',
        'Process.phase1.duration': '(AUG-NOV)',
        'Process.phase1.title': 'Discovery and Analysis',
        'Process.phase1.body': '',

        'Process.phase2.name': 'Phase 2',
        'Process.phase2.duration': '(NOV-FEB)',
        'Process.phase2.title': 'Exploration',
        'Process.phase2.body': '',

        'Process.phase3.name': 'Phase 3',
        'Process.phase3.duration': '(FEB-JUN)',
        'Process.phase3.title': 'Development and Documentation',
        'Process.phase3.body': '',

        'Process.phase4.name': 'Phase 4',
        'Process.phase4.duration': '(JUN-SEP)',
        'Process.phase4.title': 'New Phase',
        'Process.phase4.body': '',

        'Resources.title': "Further information for you to know",
        'Resources.subtitle': "Resources & Updates",

        'Resources': [
            {
                'title': 'Campus Base Map',
                'subtitle': 'Click here to see the map.',
                'image': '',
                'body': '',
            }
        ],

        'Contact.title': "Get involved today",
        'Contact.subtitle': "Contact & Share Your Ideas",
        'Contact.prompt.name': 'Hi, my name is',
        'Contact.prompt.role': "and I'm a",
        'Contact.prompt.email': "and my email address is",
        'Contact.prompt.message': "my message",
        'Contact.submit': "Send",
        'Contact.thanks': "Thanks!",
        'Contact.error': 'Ooops! There was an error.',
    }
}


export default seeds;
