const firebaseConfig = {
    apiKey: "AIzaSyDoTzSvBPww60fzR2ZAPRQnj-LNR_F_Lhs",
    authDomain: "neu-engagement.firebaseapp.com",
    projectId: "neu-engagement",
    storageBucket: "neu-engagement.appspot.com",
    messagingSenderId: "564714869742",
    appId: "1:564714869742:web:98da3db7d30575dda8b4ff"
};

export default firebaseConfig;
