import { useState } from 'react';
import { observer } from 'mobx-react';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import { ENGAGEMENT_LINK } from '../../config';


export default observer(function Landing() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Landing-wrap">
            <section className="Section Landing">
                <Anchor onChange={(anchor: any) => setPromptHidden(anchor.placement === 'offscreen')} />

                <div className="content">
                    <henshu.h2 {...bindTo('landing.header')} />

                    <henshu.a
                        className="button with-arrow"
                        href={ENGAGEMENT_LINK}
                        {...bindTo('engagement.button')}
                    />
                </div>
            </section>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
