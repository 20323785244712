import { useCallback, useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';

import Section from '../Section';


export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const { upload } = useHenshuApp().persist;
    const [height, setHeight] = useState('20px');

    const onImageRef = useCallback(ref => {
        if (ref) {
            const setDims = () => {
                const { width } = ref.getBoundingClientRect();
                setHeight(`${width / 1.47}px`);
            };

            (new ResizeObserver(setDims)).observe(ref);

            setDims();
        }
    }, []);

    return (
        <Section name="Welcome" index={0}>
            <henshu.each {...bindTo('Welcome.items')}>
                {(bindToEach, i) => (
                    <div className="row" key={i}>
                        <div className="col">
                            <henshu.p {...bindToEach('text')} />
                        </div>

                        <div className="col">
                            <div className="image-wrap" ref={onImageRef} style={{ height }}>
                                <henshu.img alt=""
                                    get={() => bindToEach('image').get()}
                                    set={async buf => bindToEach('image').set(buf ? await upload(buf) : '')}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </henshu.each>
        </Section>
    );
});
