import { henshu, useHenshu } from "@strategies/henshu";

import Section from '../Section';
import historyVideo from '../../assets/NU_Historic_Timeline.mp4';


export default function CampusHistory() {
    const { bindTo } = useHenshu();

    return (
        <Section index={1} name="CampusHistory">
            <henshu.p className="bold" {...bindTo('CampusHistory.body')} />

            <video controls autoPlay muted>
                <source src={historyVideo} type="video/mp4" />
            </video>

            <henshu.richtext {...bindTo('CampusHistory.footer')} />
        </Section>
    );
};
