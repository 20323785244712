import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';

export default observer(function Process() {
    const { bindTo } = useHenshu();

    return (
        <Section index={2} name="Process">
            <henshu.richtext {...bindTo('Process.body')} />

            <ul>
                <henshu.each {...bindTo('Process.items')}>
                    {(bindToEach, i) => (
                        <li key={i}>
                            <h4>Phase 0{i+1}</h4>
                            <henshu.h5 {...bindToEach('title')} />
                            <henshu.p {...bindToEach('body')} />
                        </li>
                    )}
                </henshu.each>
            </ul>
        </Section>
    );
});
